import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import CircularProgress from '@mui/material/CircularProgress'
import type {} from '@mui/lab/themeAugmentation'

import SelectIcon from 'images/chevron-down.svg'

import { shadows } from './shadows'
import './fonts.css'
import React from 'react'

declare module '@mui/material/styles' {
	interface TypographyVariants {
		link1: React.CSSProperties
		link2: React.CSSProperties
	}

	interface TypographyVariantsOptions {
		link1?: React.CSSProperties
		link2?: React.CSSProperties
	}

	export interface Theme {
		colors: typeof colors
		palette: typeof palette
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		link1: true
		link2: true
	}
}

// that are not part of the palette
const colors = {
	primary: '#D7F518',
	strokes: '#E8E8E4',
	elements: '#ACB4BF',
	white: '#FFFFFF',
	input: { background: '#F7F8FA', placeholder: '#939AA7', focus: '#88BFFF' },
	skeleton: '#ECEEF2',
	skeletonColor: '#ECEEF2',
	tagBackground: '#F2FCB2',
	tagText: '#485208',
}

const palette = {
	primary: {
		main: '#D7F518',
		contrastText: '#1C1D21',
		light: '#FFFFFF',
		dark: '#B3CC14',
		darker: '#6C7B0C',
	},
	secondary: { main: '#F5FFC2', contrastText: '#5B5C59', light: '#F7F7F5' },
	text: { primary: '#27291F', secondary: '#5B5C59', placeholder: '#929290' },

	success: { main: '#A7DB3D', contrastText: '#364D09', light: '#F6FFE6', dark: '#364D09' },
	warning: { main: '#EFCE20', contrastText: '#674D05', light: '#FFF9DB', dark: '#674D05' },
	error: { main: '#EB4D38', contrastText: '#9E3720', light: '#FFF6F4', dark: '#9E3720' },
	info: { main: '#4CAAE1', contrastText: '#1C5B80', light: '#EBF8FF', dark: '#1C5B80' },

	background: { default: '#FFFFFF', paper: '#F7F7F5', card: '#FDFDFB', tooltip: '#000000B2' },
	buttons: {
		main: '#1c1d21',
		contrastText: '#FFFFFF',
	},
	tonalOffset: { light: 0.1, dark: 0.1 },

	divider: '#D3D9E5',
}

const typography: TypographyOptions = {
	fontFamily: `SatoshiVariable, Helvetica, Arial, sans-serif`,
	body1: { fontSize: '1.5rem', fontWeight: 480 },
	body2: { fontSize: 20, fontWeight: 480 },
	button: { fontSize: 16, fontWeight: 650, lineHeight: 1.375, textTransform: 'none' },
	caption: { fontSize: 12, fontWeight: 480, lineHeight: 1 },
	h1: {
		fontSize: 66,
		fontWeight: 400,
		fontFamily: 'AntonRegular',
	},
	h2: { fontSize: 60, fontWeight: 400, fontFamily: 'AntonRegular' },
	h3: { fontSize: 54, fontWeight: 400, fontFamily: 'AntonRegular' },
	h4: { fontSize: 50, fontWeight: 400, fontFamily: 'AntonRegular' },
	h5: { fontSize: 18, fontWeight: 700, lineHeight: 1.5 },
	h6: { fontSize: 16, fontWeight: 700, lineHeight: 1.375 },
	link1: { fontSize: 16, fontWeight: 500, lineHeight: 1.375, textDecoration: 'underline' },
	link2: { fontSize: 14, fontWeight: 500, lineHeight: 1.125, textDecoration: 'underline' },
}

const shape = {
	buttonRadius: 10,
	paperRadius: 10,
	chipRadius: 4,
	alertRadius: 6,
	sideBarItemRadius: 8,
	errorModalRadius: 40,
	dataChipRadius: 30,
	skeletonRadius: 20,
}

const ButtonsStyles = {
	defaultProps: { variant: 'contained' as const },
	styleOverrides: {
		root: {
			borderRadius: `${shape.buttonRadius}px`,
			color: palette.text.primary,
			padding: '0.875rem 1.625rem',
			transition: 'filter 100ms ease',
			'&:disabled': { opacity: '0.5' },
			'&, &:active, &:hover': { boxShadow: 'none' },
		},
		outlined: {
			'&, &:disabled, &:focus': {
				background: palette.background.default,
				borderColor: colors.strokes,
			},
		},
		contained: {
			'&, &:disabled': {
				background: palette.primary.main,
			},
		},
		text: {
			'&, &:disabled': { color: palette.text.primary, ...typography.button },
			padding: 0,
		},
	},
}

// A custom theme for this app
export const theme = createTheme({
	palette,
	breakpoints: { values: { xs: 0, sm: 660, md: 960, lg: 1230, xl: 1920 } },
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'*': { boxSizing: 'border-box' },
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					height: '100%',
					width: '100%',
				},
				section: {
					maxWidth: '2080px',
					margin: '0 auto',
					padding: '0.25rem',
					'@media(max-width: 960px)': {
						padding: '1rem',
					},
				},
				'.primary': {
					color: colors.primary,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					background: palette.background.card,
					position: 'sticky',
					borderRadius: 0,
					top: 0,
					borderWidth: 0,
					borderBottomWidth: '1px',
					borderBottomColor: colors.strokes,
				},
			},
		},
		MuiButton: ButtonsStyles,
		MuiLoadingButton: {
			...ButtonsStyles,
			defaultProps: {
				...ButtonsStyles?.defaultProps,
				loadingIndicator: <CircularProgress color="inherit" size={'1.375rem'} />,
				loadingPosition: 'end',
			},
		},
		MuiPaper: {
			styleOverrides: { root: { borderRadius: '16px', border: `1px solid ${colors.strokes}` } },
		},
		MuiAlert: {
			defaultProps: { variant: 'outlined', severity: 'info' },
			styleOverrides: {
				root: { borderRadius: '10px' },
				outlinedInfo: {
					color: '#2C2F35',
					border: `1px solid ${colors.strokes}`,
					'.MuiAlert-icon': { color: colors.elements },
				},
				filledError: { color: '#EA404A', border: `1px solid #EA404A` },
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: colors.strokes,
						},
						'&:hover fieldset': {
							borderColor: colors.strokes,
							borderWidth: '0.15rem',
						},
					},
					'& .MuiInputLabel-asterisk': {
						color: palette.error.main,
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					paddingTop: '0.375rem !important',
					paddingBottom: '0.5rem !important',
					paddingLeft: 0,
					paddingRight: 0,
					fontSize: 16,
					fontWeight: 480,
				},
				multiline: {
					padding: 0,
				},
				inputMultiline: {
					paddingBottom: '0 !important',
				},
			},
			variants: [
				{
					props: { error: true },
					style: {
						'::before, ::after': {
							borderColor: `${palette.error.contrastText}  !important`,
						},
					},
				},
				{
					props: { error: false },
					style: {
						'::before, ::after': {
							borderColor: `${colors.strokes}  !important`,
						},
						'&:focus ::before, ::after': {
							borderColor: `${palette.primary.dark}  !important`,
						},
					},
				},
			],
		},
		MuiAccordion: {
			defaultProps: { square: true } /* to overwrite borderRadius with custom values below */,
			styleOverrides: {
				root: { borderRadius: '10px', '&:before': { opacity: 0 }, padding: '0rem 1rem' },
			},
		},
		MuiAccordionSummary: { styleOverrides: { root: { borderRadius: '10px' } } },
		MuiAccordionDetails: { styleOverrides: { root: { marginTop: '-1rem', borderRadius: '10px' } } },
		MuiTypography: {
			styleOverrides: {
				h1: { whiteSpace: 'pre-wrap' },
				h2: { whiteSpace: 'pre-wrap' },
				h3: { whiteSpace: 'pre-wrap' },
				h4: { whiteSpace: 'pre-wrap' },
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
				IconComponent: SelectIcon,
			},
			styleOverrides: {
				root: {
					'.MuiSelect-icon': { marginTop: '-0.25rem' },
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: colors.strokes,
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: colors.strokes,
						borderWidth: '0.15rem',
					},
				},
				select: {
					paddingRight: '1.625rem',
					display: 'flex',
					'&:focus, &:active, &:hover': {
						background: 'inherit',
					},
				},
				outlined: {
					padding: '0.875rem 2.25rem 0.875rem 1rem',
					height: 'fit-content',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: 16,
					fontWeight: 480,
					'&, &:focus, &:hover': {
						color: `${palette.text.secondary} !important`,
					},
					'& .MuiInputLabel-asterisk': {
						color: palette.error.main,
					},
				},
			},
		},
		MuiFormHelperText: {
			variants: [
				{
					props: { error: true },
					style: {
						...typography.body2,
						color: `${palette.error.contrastText} !important`,
					},
				},
			],
		},
		MuiChip: {
			defaultProps: {
				variant: 'filled',
				color: 'primary',
				size: 'medium',
			},
			styleOverrides: {
				root: {
					padding: '0.25rem 0.625rem',
					height: 'unset',
					background: colors.tagBackground,
					color: colors.tagText,
					borderRadius: `${shape.chipRadius}px`,
					'.MuiChip-label': { padding: 0, ...typography.body2 },
				},
			},
		},
		MuiMenuItem: {
			defaultProps: {
				divider: true,
			},
			styleOverrides: {
				root: {
					display: 'flex',
					alignItems: 'center',
					padding: '1rem',
					fontSize: 16,
					fontWeight: 480,
					background: palette.background.default,
					borderColor: colors.strokes,
					'&.Mui-selected': {
						background: colors.tagBackground,
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
	},
	typography,
	shadows,
	...{ colors },
})

import React, { PropsWithChildren, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const QueryClientRootProvider = ({ children }: PropsWithChildren<{}>) => {
	const queryClient = useMemo(
		() =>
			new QueryClient({
				// default cache time must be much bigger than commonly used stale time (5min) - like 30 minutes
				defaultOptions: {
					queries: {
						gcTime: 30 * 60 * 1000,
						throwOnError: false,
						placeholderData: (prev: any) => prev,
						retry: 3,
					},
					mutations: {
						throwOnError: false,
					},
				},
			}),
		[],
	)

	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
